<template>
  <div>
    <el-form :inline="true">
      <!-- 搜索区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:200px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-dropdown split-button type="primary" @command="getstatus">
          {{ $t('mmb.jyzt') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="3">{{
              $t('mmb.quanbu')
            }}</el-dropdown-item>
            <el-dropdown-item command="0">{{
              $t('mmb.zzjx')
            }}</el-dropdown-item>
            <el-dropdown-item command="-1">{{
              $t('mmb.jyqx')
            }}</el-dropdown-item>
            <el-dropdown-item command="1">{{ $t('mmb.yfk') }}</el-dropdown-item>
            <el-dropdown-item command="2">{{ $t('mmb.yfb') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.start"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.end"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-button icon="el-icon-search" circle @click="getlist"></el-button>
    </el-form>
    <!-- 列表区域 -->
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="code" :label="$t('mmb.danhao')"></el-table-column>
      <el-table-column prop="trade_type" :label="$t('mmb.leixing')">
        <template slot-scope="{ row }">
          <span v-if="row.trade_type === '1'" style="color:red;">买币</span>
          <span v-else style="color:green;">卖币</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="trade_num"
        :label="$t('mmb.yjsl')"
      ></el-table-column>
      <el-table-column prop="price" :label="$t('mmb.jydj')"></el-table-column>
      <el-table-column
        prop="product_type"
        :label="$t('mmb.cplx')"
      ></el-table-column>
      <el-table-column prop="status" :label="$t('zhuangtai')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '0'">{{ $t('mmb.zzjx') }}</span>
          <span v-else-if="row.status === '1'">{{ $t('mmb.yfk') }}</span>
          <span v-else-if="row.status === '2'">{{ $t('mmb.yfb') }}</span>
          <span v-else-if="row.status === '4'">{{ $t('mmb.ysb') }}</span>
          <span v-else-if="row.status === '5'">{{ $t('mmb.qrfk') }}</span>
          <span v-else-if="row.status === '6'">{{ $t('mmb.mbqx') }}</span>
          <span v-else-if="row.status === '7'">{{ $t('mmb.ysb') }}</span>
          <span v-else-if="row.status === '8'">{{ $t('mmb.jywc') }}</span>
          <span v-else>{{ $t('mmb.yqx') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column prop="remark" :label="$t('beizhu')"></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('beizhu')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="beizhu(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span v-if="status === '0'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddzzjxz') }}</span
      >
      <span v-else-if="status === '-1'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddyqx') }}</span
      >
      <span v-else-if="status === '1'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddyzf') }}</span
      >
      <span v-else-if="status === '2'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddyfb') }}</span
      >
      <span v-else-if="status === '4'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddysb') }}</span
      >
      <span v-else-if="status === '5'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddqrfk') }}</span
      >
      <span v-else-if="status === '6'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddmbqx') }}</span
      >
      <span v-else-if="status === '8'"
        >{{ $t('zhuangtai') }}:{{ $t('mmb.gddywc') }}</span
      >
      <el-divider></el-divider>
      <el-radio-group v-model="status" v-if="status1==0 || status1==1 || status1==4 || status1==5 || status1==7">
        <el-radio :label="'-1'">{{ $t('mmb.yqx') }}</el-radio>
        <el-radio :label="'0'">{{ $t('mmb.dfk') }}</el-radio>
        <el-radio :label="'1'">{{ $t('mmb.yfk') }}</el-radio>
        <el-radio :label="'2'">{{ $t('mmb.yfb') }}</el-radio>
         <el-radio :label="'5'">{{ $t('mmb.qrfk') }}</el-radio>
         <el-radio :label="'6'">{{ $t('mmb.mbqx') }}</el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer">
        <el-button  v-if="status1==0 || status1==1 || status1==4 || status1==5 || status1==7" @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button  v-if="status1==0 || status1==1 || status1==4 || status1==5 || status1==7" type="primary" @click="editsubmit">{{
          $t('queding')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('beizhu')"
      :visible="beizhuvisible"
      :before-close="beizhuclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item :label="$t('beizhu')">
          <el-input v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="beizhuclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="beizhusubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 快捷时间
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 分页请求数据对象
      queryinfo: {
        query: '',
        start: '',
        end: '',
        page: 1,
        status: 3
      },
      infolist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      status: '',
      status1: '',
      editid: '',
      beizhuvisible: false,
      editform: {},
      remark: ''
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/trade/tradelist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.total = +data.data.count
      this.order_count = data.data.o_count
      this.total_money = data.data.total
      this.profit = data.data.profit
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/trade/editlist/id/' + id)
      if (data) {
        console.log(data)
        if (data.code === 200) {
          this.editform = data.data
          this.status = data.data.status
          this.status1 = data.data.status
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 编辑提交
    async editsubmit() {
      const { data } = await this.$http.post('/admin/trade/editlist', {
        id: this.editid,
        action: 'bj',
        content: this.status
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(this.getlang(data.msg))
          this.editclose()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      this.editdialogvisible = false
    },
    // 关闭编辑弹窗
    editclose() {
      this.editdialogvisible = false
    },
    // 打开编辑弹窗
    async beizhu(id) {
      this.beizhuvisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/trade/editlist/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.editform = data.data
          this.remark = data.data.remark
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 编辑提交
    async beizhusubmit() {
      const { data } = await this.$http.post('/admin/trade/editlist', {
        id: this.editid,
        action: 'bz',
        content: this.remark
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(this.getlang(data.msg))
          this.beizhuclose()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      this.editdialogvisible = false
    },
    // 关闭编辑弹窗
    beizhuclose() {
      this.beizhuvisible = false
    },
    // 状态筛选
    getstatus(command) {
      this.queryinfo.status = command
      this.getlist()
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.tongji {
  width: 100%;
  height: 56px;
  margin: 20px 0;
  .touzi {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    margin-right: 20px;
    overflow: hidden;
    span:first-child {
      background: #2389f2;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
  .dingdan {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    overflow: hidden;
    margin-right: 20px;
    span:first-child {
      background: #ffc913;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
  .yingkui {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    overflow: hidden;
    span:first-child {
      background: #ff6213;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
}
</style>
